/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Thin.ttf");
  font-weight: 100;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-ExtraLight.ttf");
  font-weight: 200;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-ExtraBold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Black.ttf");
  font-weight: 900;
}

@font-face {
  font-family: "Comic Sans MS";
  src: url("../assets/fonts/Comic Sans MS-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Comic Sans MS";
  src: url("../assets/fonts/Comic Sans MS-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "AtkinsonHyperlegible";
  src: url("../assets/fonts/Atkinson-Hyperlegible-Regular-102.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "AtkinsonHyperlegible";
  src: url("../assets/fonts/Atkinson-Hyperlegible-Italic-102.ttf");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "AtkinsonHyperlegible";
  src: url("../assets/fonts/Atkinson-Hyperlegible-Bold-102.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "AtkinsonHyperlegible";
  src: url("../assets/fonts/Atkinson-Hyperlegible-BoldItalic-102.ttf");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "OpenDyslexic";
  src: url("../assets/fonts/OpenDyslexic-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: "OpenDyslexic";
  src: url("../assets/fonts/OpenDyslexic-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: "OpenDyslexic";
  src: url("../assets/fonts/OpenDyslexic-Bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: "OpenDyslexic";
  src: url("../assets/fonts/OpenDyslexic-Italic.otf");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "OpenDyslexic";
  src: url("../assets/fonts/OpenDyslexic-BoldItalic.otf");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Black.ttf");
  font-weight: 900;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-BlackItalic.ttf");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-BoldItalic.ttf");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Italic.ttf");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-LightItalic.ttf");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-MediumItalic.ttf");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Thin.ttf");
  font-weight: 100;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-ThinItalic.ttf");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "RobotoSerif";
  src: url("../assets/fonts/RobotoSerif-Black.ttf");
  font-weight: 900;
}

@font-face {
  font-family: "RobotoSerif";
  src: url("../assets/fonts/RobotoSerif-BlackItalic.ttf");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "RobotoSerif";
  src: url("../assets/fonts/RobotoSerif-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "RobotoSerif";
  src: url("../assets/fonts/RobotoSerif-BoldItalic.ttf");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "RobotoSerif";
  src: url("../assets/fonts/RobotoSerif-Italic.ttf");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "RobotoSerif";
  src: url("../assets/fonts/RobotoSerif-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "RobotoSerif";
  src: url("../assets/fonts/RobotoSerif-LightItalic.ttf");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "RobotoSerif";
  src: url("../assets/fonts/RobotoSerif-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "RobotoSerif";
  src: url("../assets/fonts/RobotoSerif-MediumItalic.ttf");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "RobotoSerif";
  src: url("../assets/fonts/RobotoSerif-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "RobotoSerif";
  src: url("../assets/fonts/RobotoSerif-SemiBold.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "RobotoSerif";
  src: url("../assets/fonts/RobotoSerif-SemiBoldItalic.ttf");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "RobotoSerif";
  src: url("../assets/fonts/RobotoSerif-Thin.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "RobotoSerif";
  src: url("../assets/fonts/RobotoSerif-ThinItalic.ttf");
  font-weight: 100;
  font-style: italic;
}

/** Ionic CSS Variables **/
:root {
  --ion-font-family: "Inter", "Helvetica", sans-serif;
  /* --ion-font-family: 'OpenDyslexic', 'Helvetica', sans-serif; */
  /* --ion-font-family: "Comic Sans MS", "Comic Sans", cursive; */
  /* --ion-font-family: "Droid Sans Mono", cursive; */

  --font-size-change: 0px;

  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** ------------ AIMER COLORS ----------- **/

  /* don't use these grey/black/white colors directly - use background, text etc.. */
  --grey-00: #f8f9fa;
  --grey-10: #f1f3f5;
  --grey-20: #e8ecef;
  --grey-30: #dee2e6;
  --grey-40: #ced4da;
  --grey-50: #adb5bd;
  --grey-60: #868e96;
  --grey-70: #495057;
  --grey-80: #343a40;
  --grey-90: #212529;
  --black: #000000;
  --white: #ffffff;

  --bookmark: #118a60;
  --accent-white: #ffffff;
  --category-algorithms: #73667a;
  --category-general-guidance: #4d3ea2;
  --category-maternity-care: #b42199;
  --category-medical: #2ea100;
  --category-meds: #eb6c16;
  --category-page-for-age: #e91581;
  --category-resuscitation: #00a3b1;
  --category-special-situations: #7b6f18;
  --category-trauma: #ed1d25;
  --category-default: #287b73;
  --cta-warning: #ed242b;
  --cta: #007aff;
  --grey-info-background: var(--grey-30);
  --grey-info-text: var(--grey-80);
  --pathway-green: #02704a;

  --cfr-yellow: #e7e457;
  --icpg-blue-30: #23b8a8;
  --icpg-blue-50: #287b73;
  --icpg-blue-60: #005e55;
  --icpg-blue-70: #004745;
  --icpg-green: #036f49;
  --primary-40: #2ca972;
  --primary-50: #02704a;
  --primary-60: #016047;
  --tab-bar-fill: #f7faf8;
  --tab-bar-separator: transparent;

  --background-keypoints: #e2f2f0;
  --background-warning: #fef0ea;
  --background-green-box: #c9d7c5;

  --background-BRISTOW: #c8ebf2;
  --background-EMAS: #b3e3e0;
  --background-EEAST: #b3e3c5;
  --background-IOM: #d1ecf0;
  --background-JAS: #dadde4;
  --background-LAS: #b3e3e0;
  --background-NIAS: #cbe3b3;
  --background-NEAS: #dadde4;
  --background-NEOM: #f0ebe6;
  --background-NWAS: #b3e3ca;
  --background-ROLE1: #c8ebf2;
  --background-SAS: #d1d9e8;
  --background-SCAS: #d1d9e8;
  --background-SECAMB: #c8ebf2;
  --background-SJA: #b3e3e0;
  --background-SWAST: #d1e2f8;
  --background-SJARS: #b3e3e0;
  --background-TST: #f7edf7;
  --background-WAS: #d1d9e8;
  --background-WMAS: #d1d9e8;
  --background-YAS: #c8ebf2;

  --background-primary: var(--grey-10);
  --background-secondary: var(--white);
  --background-tertiary: var(--grey-00);
  --background-guidelines: var(--white);
  --background-cta-text: var(--grey-30);

  --border: var(--grey-40);
  --border-light: var(--grey-30);
  --border-warning: #b0788d;

  --text-00: var(--grey-00);
  --text-10: var(--grey-10);
  --text-20: var(--grey-20);
  --text-30: var(--grey-30);
  --text-40: var(--grey-40);
  --text-50: var(--grey-50);
  --text-60: var(--grey-60);
  --text-70: var(--grey-70);
  --text-80: var(--grey-80);
  --text-90: var(--grey-90);
  --text-90: var(--grey-90);
  --text-100: var(--black);
  --text-highlight: #dcebfd;
  --link: blue;
  --text-warning: #7e2b48;
  --green: green;
}

/*
 * Dark Colors
 * -------------------------------------------
 */

body.dark {
  /** ------------ AIMER COLORS ----------- **/
  --cta: #3395ff;

  --background-primary: var(--grey-90);
  --background-secondary: var(--grey-80);
  --background-tertiary: var(--grey-70);
  --background-guidelines: #0b0d0e;
  --background-cta-text: var(--grey-70);

  --border: var(--grey-60);
  --border-light: var(--grey-60);
  --border-warning: #4d3132;

  --text-00: var(--grey-90);
  --text-10: var(--grey-80);
  --text-20: var(--grey-70);
  --text-30: var(--grey-60);
  --text-40: var(--grey-50);
  --text-50: var(--grey-40);
  --text-60: var(--grey-30);
  --text-70: var(--grey-20);
  --text-80: var(--grey-10);
  --text-90: var(--grey-00);
  --text-100: var(--white);
  --text-highlight: #3b90fd;
  --link: var(--cta);
  --text-warning: var(--white);
  --green: #a6d2a6;

  --tab-bar-fill: #1f2326;
  --tab-bar-separator: var(--grey-70);

  --background-keypoints: #013335;
  --background-warning: #492828;
  --background-green-box: #013335;
  --pathway-green: #58d490;

  --background-BRISTOW: #18464d;
  --background-EMAS: #074945;
  --background-IOM: #214d52;
  --background-JAS: #414449;
  --background-LAS: #074945;
  --background-NEAS: #414449;
  --background-NEOM: #4c453e;
  --background-NIAS: #294a07;
  --background-NWAS: #074945;
  --background-ROLE1: #18464d;
  --background-SAS: #424653;
  --background-SCAS: #424653;
  --background-SECAMB: #18464d;
  --background-SJA: #074945;
  --background-SJARS: #074945;
  --background-SWAST: #294361;
  --background-TST: #421346;
  --background-WAS: #424653;
  --background-WMAS: #424653;
  --background-YAS: #18464d;

  --ion-color-primary: #428cff;
  --ion-color-primary-rgb: 66, 140, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3a7be0;
  --ion-color-primary-tint: #5598ff;

  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80, 200, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;
}

/*
 * iOS Dark Theme
 * -------------------------------------------
 */
.ios body.dark {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: #000000;

  --ion-card-background: #1c1c1d;
}

/*
 * Material Design Dark Theme
 * -------------------------------------------
 */

.md body.dark {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;

  --ion-card-background: #1e1e1e;
}

.ios ion-modal body.dark {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
}
